import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { DashboardTopSeller } from "@data/schemas/dashboard/dashboard-top-seller";
import { AuthService } from "@core/services/auth/auth.service";
import { environment } from "@env";
import { BaseCrudBffService } from "@shared/services/base-crud-bff.service";
import { DashboardSalesPerformance } from "@data/schemas/dashboard/dashboard-sales-performance";
import { BehaviorSubject, Observable, timer } from "rxjs";
import { DashboardEmptyStateSeller } from "@data/schemas/dashboard/dashboard-empty-state-seller";
import { DashboardBankBalance } from "@data/schemas/dashboard/dashboard-bank-balance";
import { DashboardIncome } from "@data/schemas/dashboard/dashboard-income";
import { DashboardCashFlow } from "@data/schemas/dashboard/dashboard-cash-flow";
import { DashboardEmptyStateFinance } from "@data/schemas/dashboard/dashboard-empty-state-finance";

interface OptinNewDashbard {
	idUser: string;
	idPersistenceUnit: string;
	optin: boolean;
}

@Injectable({
	providedIn: "root"
})
export class DashboardService {
	private updateAll = new BehaviorSubject<string>("thisMonth");
	updateAll$ = this.updateAll.asObservable();

	private updateFinancialSummaryCards = new BehaviorSubject<string>(
		"thisMonth"
	);
	updateFinancialSummaryCards$ =
		this.updateFinancialSummaryCards.asObservable();

	private updateBankBalance = new BehaviorSubject<boolean>(true);
	updateBankBalance$ = this.updateBankBalance.asObservable();

	private updateCashFlow = new BehaviorSubject<
		"thisMonth" | "thisYear" | "custom"
	>("thisMonth");
	updateCashFlow$ = this.updateCashFlow.asObservable();

	private updateSalesCards = new BehaviorSubject<string>("thisMonth");
	updateSalesCards$ = this.updateSalesCards.asObservable();

	private updateSalesFunel = new BehaviorSubject<string>("thisMonth");
	updateSalesFunel$ = this.updateSalesFunel.asObservable();

	private updateTopSellers = new BehaviorSubject<string>("thisMonth");
	updateTopSellers$ = this.updateTopSellers.asObservable();

	private updateSalesPerformance = new BehaviorSubject<string>("");
	updateSalesPerformance$ = this.updateSalesPerformance.asObservable();

	private updateIncomeFinance = new BehaviorSubject<
		"thisMonth" | "thisYear" | "custom"
	>("thisMonth");
	updateIncomeFinance$ = this.updateIncomeFinance.asObservable();

	hideValues = signal<boolean>(true);

	areAllCardsEmpty = signal<boolean>(false);

	showPreRelease = signal<boolean>(false);

	showNewDashboard = signal<boolean | null>(null);

	showFeedbackModalLegacyAfter30Seconds$ = timer(30000).pipe();

	constructor(
		private authService: AuthService,
		private baseCrudBff: BaseCrudBffService,
		private http: HttpClient
	) {}

	listCommercialProposal(sortBy: string): Observable<{
		totalValueSalesProposal: number;
		valuePercentageDifference: number;
		countSalesProposal: number;
		countPercentageDifference: number;
	}> {
		return this.baseCrudBff.getAll({
			path: "dashboard/comercial-summary",
			params: { sortBy }
		});
	}

	listSalesStarted(sortBy: string): Observable<{
		totalValueSalesInitiated: number;
		valuePercentageDifference: number;
		countSalesInitiated: number;
		countPercentageDifference: number;
	}> {
		return this.baseCrudBff.getAll({
			path: "dashboard/sales-started",
			params: { sortBy }
		});
	}

	listSalesFinished(sortBy: string): Observable<{
		totalValueSalesCompleted: number;
		valuePercentageDifference: number;
		countSalesCompleted: number;
		countPercentageDifference: number;
	}> {
		return this.baseCrudBff.getAll({
			path: "dashboard/sales-finished",
			params: { sortBy }
		});
	}

	listTicket(sortBy: string): Observable<{
		totalAverageTicket: number;
		percentualDifference: number;
	}> {
		return this.baseCrudBff.getAll({
			path: "dashboard/ticket",
			params: { sortBy }
		});
	}

	listTopSellers(
		sortBy: string
	): Observable<{ content: DashboardTopSeller[] }> {
		return this.baseCrudBff.getAll({
			path: "dashboard/top-sellers",
			params: { sortBy }
		});
	}

	listSalesPerformance(): Observable<{
		content: DashboardSalesPerformance[];
	}> {
		return this.baseCrudBff.getAll({
			path: "dashboard/sales-performance"
		});
	}

	findIncome(
		sortBy: "thisMonth" | "thisYear" | "custom" = "thisMonth"
	): Observable<DashboardIncome> {
		return this.http.get<DashboardIncome>(
			`${environment.API_BFF}/dashboard/income/?hashedFingerprint=${this.authService.getHashedFingerprint()}&sortBy=${sortBy}`,
			{
				withCredentials: true
			}
		);
	}

	updateAllDataSeller(sortBy?: "thisMonth" | "thisYear"): void {
		this.updateSalesCardsData(sortBy);
		this.updateSalesFunelData(sortBy);
		this.updateTopSellersData(sortBy);
		this.updateSalesPerformanceData();
	}

	updateAllDataFinance(sortBy?: "thisMonth" | "thisYear"): void {
		this.updateFinancialSummaryCardsData(sortBy);
		this.updateBankBalanceData();
		this.updateIncomeFinanceData(sortBy);
		this.updateCashFlowData(sortBy);
	}

	updateAllDataAdmin(sortBy?: "thisMonth" | "thisYear"): void {
		this.updateAllDataSeller(sortBy);
		this.updateAllDataFinance(sortBy);
	}

	updateSalesCardsData(sortBy?: "thisMonth" | "thisYear" | "custom"): void {
		this.updateSalesCards.next(sortBy ?? "");
	}

	updateSalesFunelData(sortBy?: "thisMonth" | "thisYear" | "custom"): void {
		this.updateSalesFunel.next(sortBy ?? "");
	}

	updateTopSellersData(sortBy?: "thisMonth" | "thisYear" | "custom"): void {
		this.updateTopSellers.next(sortBy ?? "");
	}

	updateSalesPerformanceData(): void {
		this.updateSalesPerformance.next("");
	}

	updateFinancialSummaryCardsData(
		sortBy?: "thisMonth" | "thisYear" | "custom"
	): void {
		this.updateFinancialSummaryCards.next(sortBy ?? "");
	}

	updateBankBalanceData(): void {
		this.updateBankBalance.next(true);
	}

	updateIncomeFinanceData(
		sortBy?: "thisMonth" | "thisYear" | "custom"
	): void {
		this.updateIncomeFinance.next(sortBy ?? "thisMonth");
	}

	updateCashFlowData(
		sortBy: "thisMonth" | "thisYear" | "custom" = "thisMonth"
	): void {
		this.updateCashFlow.next(sortBy);
	}

	hideAllData(): void {
		this.hideValues.set(!this.hideValues());
	}

	updateShowPreRelease(status: boolean): void {
		this.showPreRelease.set(status);
	}

	getOptinNewDashboard(id: string): Observable<OptinNewDashbard> {
		return this.http.get<OptinNewDashbard>(
			`${environment.API_BFF}/optin-new-dashboard/${id}/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	createNewOptinDashboard(
		user: OptinNewDashbard
	): Observable<OptinNewDashbard> {
		return this.http.post<OptinNewDashbard>(
			`${environment.API_BFF}/optin-new-dashboard/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			user,
			{
				withCredentials: true
			}
		);
	}

	updateOptinNewDashboard(
		user: OptinNewDashbard
	): Observable<OptinNewDashbard> {
		return this.http.patch<OptinNewDashbard>(
			`${environment.API_BFF}/optin-new-dashboard/${user.idUser}-${user.idPersistenceUnit}/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			user,
			{
				withCredentials: true
			}
		);
	}

	clearCacheSeller(): Observable<{ lastCacheUpdate: string }> {
		return this.http.post<{ lastCacheUpdate: string }>(
			`${environment.API_BFF}/dashboard/clear-cache-seller`,
			{ hashedFingerprint: this.authService.getHashedFingerprint() },
			{
				withCredentials: true
			}
		);
	}

	clearCacheFinance(): Observable<{ lastCacheUpdate: string }> {
		return this.http.post<{ lastCacheUpdate: string }>(
			`${environment.API_BFF}/dashboard/clear-cache-finance`,
			{ hashedFingerprint: this.authService.getHashedFingerprint() },
			{
				withCredentials: true
			}
		);
	}

	setShowNewDashboard(status: boolean): void {
		this.showNewDashboard.set(status);
	}

	getCacheSeller(): Observable<{ lastCacheUpdate: string }> {
		return this.http.get<{ lastCacheUpdate: string }>(
			`${environment.API_BFF}/dashboard/cache-seller/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	getCacheFinance(): Observable<{ lastCacheUpdate: string }> {
		return this.http.get<{ lastCacheUpdate: string }>(
			`${environment.API_BFF}/dashboard/cache-finance/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findEmptyStateSeller(): Observable<DashboardEmptyStateSeller> {
		return this.http.get<DashboardEmptyStateSeller>(
			`${environment.API_BFF}/dashboard/empty-state-seller/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findEmptyStateFinance(): Observable<DashboardEmptyStateFinance> {
		return this.http.get<DashboardEmptyStateFinance>(
			`${environment.API_BFF}/dashboard/empty-state-finance/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findFinancialSummaryToReceive(): Observable<{
		receivablesToday: number;
		receivableMonth: number;
	}> {
		return this.http.get<{
			receivablesToday: number;
			receivableMonth: number;
		}>(
			`${environment.API_BFF}/dashboard/financial-summary-to-receive/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findFinancialSummaryOverdueReceipts(): Observable<{
		overdueReceivables: number;
	}> {
		return this.http.get<{
			overdueReceivables: number;
		}>(
			`${environment.API_BFF}/dashboard/financial-summary-overdue-receipts/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findFinancialSummaryToPaidTodayMonth(): Observable<{
		payableToday: number;
		payableMonth: number;
	}> {
		return this.http.get<{
			payableToday: number;
			payableMonth: number;
		}>(
			`${environment.API_BFF}/dashboard/financial-summary-topaid-today-month/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findFinancialSummaryLatePayment(): Observable<{
		overduePayable: number;
	}> {
		return this.http.get<{
			overduePayable: number;
		}>(
			`${environment.API_BFF}/dashboard/financial-summary-late-payment/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findBankBalance(): Observable<DashboardBankBalance[]> {
		return this.http.get<DashboardBankBalance[]>(
			`${environment.API_BFF}/dashboard/bank-balance/?hashedFingerprint=${this.authService.getHashedFingerprint()}`,
			{
				withCredentials: true
			}
		);
	}

	findCashFlow(
		sortBy: "thisMonth" | "thisYear" | "custom" = "thisMonth"
	): Observable<DashboardCashFlow> {
		return this.http.get<DashboardCashFlow>(
			`${environment.API_BFF}/dashboard/cash-flow/?hashedFingerprint=${this.authService.getHashedFingerprint()}&sortBy=${sortBy}`,
			{
				withCredentials: true
			}
		);
	}
}
